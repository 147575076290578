const FOOTER_LANG_SWITCH = '[data-footer-lang-switch]';

export default class FooterLanguageSwitch {
    constructor(element) {
        const component = this;
        component.$element = $(element);
        component.bindEvents();
    }

    bindEvents() {
        const component = this;
        const urlSwitch = '/language/switch/?returnUrl=';
        const pathName = `${window.location.pathname}${window.location.search}${window.location.hash}`;

        component.$element.on('click', function() {
            window.location.href = `${urlSwitch}${pathName}`;
        });
    }
}

$(FOOTER_LANG_SWITCH).each(
    (index, element) => new FooterLanguageSwitch(element)
);
